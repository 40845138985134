import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import TextBox from "../../Components/TextBox";
import LocalImage from "../../Assets/imagesText/10817176_19406483.jpg";

import "./Styles.css";

const Local = () => {
  return (
    <>
      <Header />
      <div className="ajust-text-local">
        <TextBox
          largura="400"
          style="column"
          image={LocalImage}
          text="Local"
          paragraf="As instalações da RCM MARINE OFFSHORE do Porto do Açu está localizada em 4 aréas ( terreno de 52.300m2 com um cais de 90m,Molhe sul com 250mts de cais e 6.000 m2 de retro-aréa,mais 2 terrenos canteirópolis com 13.700m2 área e 50mil m2 de área (900mts) próxima ao molhe sul). Nestas bases marítimas do Açu, pretendemos atender a todas necessidades e expectativas dos nossos clientes e parceiros ligados direta e indiretamente ao Superporto do Açu."
        />
      </div>
      <Footer />
    </>
  );
};

export default Local;
