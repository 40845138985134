import React from "react";
import { Link } from "react-router-dom";
import Close from "../../Assets/close_FILL0_wght400_GRAD0_opsz48.svg";
import "./style.css";

const index = (props) => {
  const ArrayServicosMenuRota = [
    { id: 1, text: "Ancoragem" },
    { id: 2, text: "Armazenamento multi-modal" },
    { id: 3, text: "Manutenção de equipamentos" },
    { id: 4, text: "Docagem" },
    { id: 5, text: "Abastecimento" },
    { id: 6, text: "Carregamento e descarregamento de embarcações" },
    { id: 7, text: "Rancho" },
    { id: 8, text: "Logística" },
    { id: 9, text: "Contentore" },
    { id: 10, text: "Serviços especializados de inspeção" },
    { id: 11, text: "Serviço de alpinismo" },
    { id: 12, text: "Limpezas" },
    { id: 13, text: " Pintura , caldeiraria, solda e usinagem" },
    { id: 14, text: "Reparo e fornecimento Drilling e Colunas de Produção" },
    // parei aqui
    { id: 15, text: "Lift gear" },
    // parei aqui
    { id: 16, text: "Bobinamento" },
    { id: 17, text: "Isolamento térmico" },
    { id: 18, text: "Teste de carga" },
    { id: 19, text: "Fornecimento de materiais de aplicação" },
    { id: 20, text: "Fornecimento de EPI´s" },
    {
      id: 21,
      text: "Fornecimento de ferramentas manuais, elétricas, pneumáticas e hidráulicas",
    },
    { id: 22, text: " Fornecimento de insumos " },
    { id: 23, text: " Andaimes" },
    { id: 24, text: "Logística pessoal" },
    { id: 25, text: "Fornecimento de kit SOPEP" },
    {
      id: 26,
      text: "Projetos De Design",
    },
    // {
    //   id: 27,
    //   text: "Confecção de peças termoplásticas sob desenho ou amostra",
    // },
    // {
    //   id: 28,
    //   text: " Soluções anticorrosivas com aplicação de revestimentos de alta performance",
    // },
  ];
  function mudarRota() {
    let pegaridvalue = document.getElementById("select-option-value").value;
    window.location.href = `/${pegaridvalue} `;
  }
  return (
    <div className="flex-column-links">
      <div onClick={props.close} id="close">
        <img src={Close} alt="" srcset="" width={25} />
      </div>
      <Link to={"/"}>Inicio</Link>
      {/* <Link to={"/"}>Sobre</Link> */}
      <Link to={"/Local"}>Localização</Link>
      <Link to={"/Certificado"}>Certificações</Link>
      <select id="select-option-value" onChange={mudarRota}>
        <option select value="">
          Serviços e Produtos
        </option>{" "}
        {ArrayServicosMenuRota.map((item) => {
          console.log(item);
          return (
            <>
              <option value={`${item.id}value`}>{item.text}</option>
            </>
          );
        })}
      </select>
      <button
        onClick={() => {
          window.location.href = "https://contate.me/rcmmarine";
        }}
      >
        Fale conosco
      </button>
      {/* <button
          onClick={() => {
            window.location.href =
              "../../Assets/Apresentação RCM MARINE PT-BR NOVA.pptx.pdf";
          }}
        >
          Apresentação
        </button> */}
    </div>
  );
};

export default index;
