import React from "react";
import "./Styles.css";
import Iframe from "react-iframe";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

const Certificado = () => {
  return (
    <>
      <Header></Header>
      <div className="iframe-height">
        <Iframe
          height="100%"
          width="100%"
          src="https://rcmmarineoffshore.com/certificado.pdf"
        ></Iframe>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Certificado;
