import React, { useState } from "react";
import Main from "../../Components/Main";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header/";
import TextBox from "../../Components/TextBox";
import Logo from "../../Assets/LOGO-RCM-COLOR-COM-FUNDO[1].png";
import imageEquipe from "../../Assets/imagesText/business-hands-joined-together-teamwork.jpg";
import imageValores from "../../Assets/imagesText/18407468_5995357.jpg";

import "./Styles.css";

const Home = () => {
  return (
    <div>
      <Header />
      <Main />
      <TextBox
        largura="400"
        style="row"
        image={Logo}
        text="Visão"
        paragraf="Ser uma empresa referência nos Serviços e segmentos que se propõe, com o compromisso do aperfeiçoamento contínuo das suas especialidades, baseadas no desenvolvimento sustentável e a rentabilidade dos seus negócios."
      />
      <TextBox
        largura="400"
        style="row-reverse"
        image={imageEquipe}
        text="Missão"
        paragraf="Disponibilizar na base portuária do Açu uma Unidade de Serviços de excelência, voltada à Qualidade, Gestão de Pessoas e o seu  desenvolvimento intelectual, fortalecendo as relações entre seus parceiros, clientes, colaboradores e fornecedores."
      />
      <TextBox
        largura="300"
        style="row"
        image={imageValores}
        text="Valores"
        paragraf="Ética e integridade nas condutas pessoais e profissionais, valorização e respeito as pessoas, ampliação do conhecimento e aprendizado, alta performance e qualidade nos serviços executados, relacionamento transparente com clientes, colaboradores e fornecedores, cumprimento das responsabilidades sociais e ambientais, empreender e pensar diferente."
      />
      <Footer />
    </div>
  );
};

export default Home;
