import React from "react";
import ancoragem from "../../../../Assets/images/servicos/ancoragem/WhatsApp Image 2022-12-02 at 11.45.36.jpeg";
import Footer from "../../../../Components/Footer";
import Header from "../../../../Components/Header";

import "../../Styles.css";

const Ancoragem = () => {
  return (
    <div>
      <Header />
      <div id="main-servico-geral" className="ancoragem">
        <h2>Ancoragem</h2>
        <img src={ancoragem} alt="" srcset="" />
      </div>
      <Footer />
    </div>
  );
};

export default Ancoragem;
