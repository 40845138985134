import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home";
import Local from "./Pages/Local";
import One from "./Pages/Servicos/PaginasDeSevicos/ancoragem";
import Two from "./Pages/Servicos/PaginasDeSevicos/armazenamento";
import Three from "./Pages/Servicos/PaginasDeSevicos/manutencao";
import Four from "./Pages/Servicos/PaginasDeSevicos/docagem";
import Five from "./Pages/Servicos/PaginasDeSevicos/abastecimento";
import Six from "./Pages/Servicos/PaginasDeSevicos/carregamento";
import Seven from "./Pages/Servicos/PaginasDeSevicos/Rancho";
import Eight from "./Pages/Servicos/PaginasDeSevicos/Logistica";
import Nine from "./Pages/Servicos/PaginasDeSevicos/Contentore";
import Twelve from "./Pages/Servicos/PaginasDeSevicos/limpezas";
import Ten from "./Pages/Servicos/PaginasDeSevicos/inspecao";
import Eleven from "./Pages/Servicos/PaginasDeSevicos/alpinismo";
import Thirteen from "./Pages/Servicos/PaginasDeSevicos/usinagem";
import Fourteen from "./Pages/Servicos/PaginasDeSevicos/drilling";
import Fiveteen from "./Pages/Servicos/PaginasDeSevicos/liftgear";
import Sixteen from "./Pages/Servicos/PaginasDeSevicos/Bobinamento";
import Seventeen from "./Pages/Servicos/PaginasDeSevicos/termico";
import Eightteen from "./Pages/Servicos/PaginasDeSevicos/testedecarga";
import Nineteen from "./Pages/Servicos/PaginasDeSevicos/aplicacao";
import Twenty from "./Pages/Servicos/PaginasDeSevicos/epis";
import TwentyOne from "./Pages/Servicos/PaginasDeSevicos/ferramentasfornecimento";
import TwentyTwo from "./Pages/Servicos/PaginasDeSevicos/insumos";
import TwentyThree from "./Pages/Servicos/PaginasDeSevicos/andaime";
import TwentyFour from "./Pages/Servicos/PaginasDeSevicos/logisticapessoal";
import TwentyFive from "./Pages/Servicos/PaginasDeSevicos/sopep";
import TwentySix from "./Pages/Servicos/PaginasDeSevicos/projetos";
import Certificado from "./Pages/certificado";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/Local" element={<Local />}></Route>
          <Route exact path="/1value" element={<One />}></Route>
          <Route exact path="/2value" element={<Two />}></Route>
          <Route exact path="/3value" element={<Three />}></Route>
          <Route exact path="/4value" element={<Four />}></Route>
          <Route exact path="/5value" element={<Five />}></Route>
          <Route exact path="/6value" element={<Six />}></Route>
          <Route exact path="/7value" element={<Seven />}></Route>
          <Route exact path="/8value" element={<Eight />}></Route>
          <Route exact path="/9value" element={<Nine />}></Route>
          <Route exact path="/10value" element={<Ten />}></Route>
          <Route exact path="/11value" element={<Eleven />}></Route>
          <Route exact path="/12value" element={<Twelve />}></Route>
          <Route exact path="/13value" element={<Thirteen />}></Route>
          <Route exact path="/14value" element={<Fourteen />}></Route>
          <Route exact path="/15value" element={<Fiveteen />}></Route>
          <Route exact path="/16value" element={<Sixteen />}></Route>
          <Route exact path="/17value" element={<Seventeen />}></Route>
          <Route exact path="/18value" element={<Eightteen />}></Route>
          <Route exact path="/19value" element={<Nineteen />}></Route>
          <Route exact path="/20value" element={<Twenty />}></Route>
          <Route exact path="/21value" element={<TwentyOne />}></Route>
          <Route exact path="/22value" element={<TwentyTwo />}></Route>
          <Route exact path="/23value" element={<TwentyThree />}></Route>
          <Route exact path="/24value" element={<TwentyFour />}></Route>
          <Route exact path="/25value" element={<TwentyFive />}></Route>
          <Route exact path="/26value" element={<TwentySix />}></Route>
          <Route exact path="/Certificado" element={<Certificado />}></Route>

          {/* <Route exact path="/AreaCliente" element={<AreaCliente />}></Route>
          <Route exact path="/Capelas" element={<Capelas />}></Route>
          <Route exact path="/Servicos" element={<Servicos />}></Route>
          <Route exact path="/Endereco" element={<Endereco />}></Route> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
