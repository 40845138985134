import React from "react";
import Logo from "../../Assets/LOGO-RCM-COLOR-COM-FUNDO[1].png";
import "./Styles.css";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-text">
        <div className="footer-text-dados">
          <img
            onClick={() => (window.location.href = "/")}
            src={Logo}
            alt="Logo"
            width="200"
            srcset=""
          />
        </div>
        <div className="footer-text-dados">
          <p>Dados</p>
          <ul>
            <li>CNPJ: 033.308.533/001-94</li>
            {/* <li>ENDEREÇO: Via 5 Projetada S/N, Lote A12</li> */}
            <li>E-MAIL: comercial@rcmmarineoffshore.com</li>
          </ul>
        </div>
        <div className="footer-text-dados">
          <p>Site</p>
          <ul>
            <li onClick={() => (window.location.href = "/")}>Inicio</li>
            <li onClick={() => (window.location.href = "/Local")}>
              Localização
            </li>
            <li onClick={() => (window.location.href = "/")}>Certificações</li>
          </ul>
        </div>
      </div>
      <div className="todos-reservados">
        {" "}
        2022 © Todos os direitos reservados.
      </div>
    </div>
  );
};

export default Footer;
