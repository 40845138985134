import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/LOGO-RCM-COLOR-COM-FUNDO[1].png";
import MenuHb from "../../Assets/menu_FILL0_wght400_GRAD0_opsz48.svg";
import BarMenu from "../../Components/barMenu/index";
import TfiMenu from "react-icons";
import "./Style.css";

const Header = () => {
  const ArrayServicosMenuRota = [
    { id: 1, text: "Ancoragem" },
    { id: 2, text: "Armazenamento multi-modal" },
    { id: 3, text: "Manutenção de equipamentos" },
    { id: 4, text: "Docagem" },
    { id: 5, text: "Abastecimento" },
    { id: 6, text: "Carregamento e descarregamento de embarcações" },
    { id: 7, text: "Rancho" },
    { id: 8, text: "Logística" },
    { id: 9, text: "Contentores" },
    { id: 10, text: "Serviços especializados de inspeção" },
    { id: 11, text: "Serviço de alpinismo" },
    { id: 12, text: "Limpezas" },
    { id: 13, text: " Pintura , caldeiraria, solda e usinagem" },
    { id: 14, text: "Reparo e fornecimento Drilling e Colunas de Produção" },
    // parei aqui
    { id: 15, text: "Lift gear" },
    // parei aqui
    { id: 16, text: "Bobinamento" },
    { id: 17, text: "Isolamento térmico" },
    { id: 18, text: "Teste de carga" },
    { id: 19, text: "Fornecimento de materiais de aplicação" },
    { id: 20, text: "Fornecimento de EPI´s" },
    {
      id: 21,
      text: "Fornecimento de ferramentas manuais, elétricas, pneumáticas e hidráulicas",
    },
    { id: 22, text: " Fornecimento de insumos " },
    { id: 23, text: " Andaimes" },
    { id: 24, text: "Logística pessoal" },
    { id: 25, text: "Fornecimento de kit SOPEP" },
    {
      id: 26,
      text: "Projetos De Design",
    },
    // {
    //   id: 27,
    //   text: "Confecção de peças termoplásticas sob desenho ou amostra",
    // },
    // {
    //   id: 28,
    //   text: " Soluções anticorrosivas com aplicação de revestimentos de alta performance",
    // },
  ];
  const [array, setArray] = useState(ArrayServicosMenuRota);
  const [menu, setMenu] = useState(false);
  function mudarRota() {
    let pegaridvalue = document.getElementById("select-option-value").value;
    window.location.href = `/${pegaridvalue} `;
  }

  function OpenMenu() {
    setMenu(true);
  }
  function CloseMenu() {
    setMenu(false);
  }
  return (
    <div id="header">
      <div id="menu-hamburguer" onClick={OpenMenu}>
        <img src={MenuHb} alt="" srcset="" width={40} />
      </div>{" "}
      <div className="logo-header">
        <img
          onClick={() => {
            window.location.href = "/";
          }}
          src={Logo}
          width="120"
          alt="Logo"
          srcset=""
        />
      </div>
      {menu == true ? <BarMenu close={CloseMenu} /> : ""}
      <div className="header-nav-menu">
        <Link to={"/"}>Inicio</Link>
        {/* <Link to={"/"}>Sobre</Link> */}
        <Link to={"/Local"}>Localização</Link>
        <Link to={"/Certificado"}>Certificações</Link>
        <select id="select-option-value" onChange={mudarRota}>
          <option select value="">
            Serviços & Produtos
          </option>{" "}
          {ArrayServicosMenuRota.map((item) => {
            console.log(item);
            return (
              <>
                <option value={`${item.id}value`}>{item.text}</option>
              </>
            );
          })}
        </select>
      </div>
      <div className="button-header">
        <button
          onClick={() => {
            window.location.href = "https://contate.me/rcmmarine";
          }}
        >
          Fale conosco
        </button>
        {/* <button
          onClick={() => {
            window.location.href =
              "../../Assets/Apresentação RCM MARINE PT-BR NOVA.pptx.pdf";
          }}
        >
          Apresentação
        </button> */}
      </div>
    </div>
  );
};

export default Header;
