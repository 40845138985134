import React from "react";
import Logo from "../../Assets/LOGO-RCM-COLOR-COM-FUNDO[1].png";
import "./Styles.css";

const TextBox = (props) => {
  return (
    <div className="text-box-container" style={{ flexDirection: props.style }}>
      <div className="img-text-box-container">
        <img src={props.image} width={props.largura} alt="" srcset="" />
      </div>
      <div className="text-text-box-container">
        <div className="text-text-box-container-minum">
          <h2>{props.text}</h2>
          <p>{props.paragraf}</p>
        </div>
      </div>
    </div>
  );
};

export default TextBox;
