import React from "react";
import "./Styles.css";

const Main = () => {
  return (
    <div id="main">
      <div className="main-primary-back">
        <h2>
          RCM <span>MARINER</span> <br />
          <button
            id="contato-main"
            onClick={() =>
              (window.location.href = "https://contate.me/rcmmarine")
            }
          >
            Entre em contato
          </button>
        </h2>
      </div>
    </div>
  );
};

export default Main;
