import React from "react";
import arm from "../../../../Assets/images/servicos/epis/Screenshot.png";
import Footer from "../../../../Components/Footer";
import Header from "../../../../Components/Header";

import "../../Styles.css";

const Ancoragem = () => {
  return (
    <div>
      <Header />
      <div id="main-servico-geral" className="ancoragem">
        <h2>FORNECIMENTO DE EPI´s</h2>
        <img src={arm} alt="" srcset="" />
      </div>
      <Footer />
    </div>
  );
};

export default Ancoragem;
